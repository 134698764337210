// turbolinks loading transitions

export default {

  init: () => {

    if (typeof Turbo !== 'undefined') {

      $(document).on('turbo:click', e => {
        $('html').addClass('loading')
      });

      $(document).on('turbo:render ready', e => {
        $('html').removeClass('loading')
      });

    }

  }

}