export default {
  init() {

    // contact form

    $('form[data-ajax]')
      .on('submit', e => {
        const form = $(e.currentTarget);

        form.addClass('form--submitting');
        form.find('.alert').remove();

        $.getJSON({
          url: env.ajaxUrl,
          type: 'post',
          data: new FormData(e.currentTarget),
          cache: false,
          contentType: false,
          processData: false,          
        })
        .always(response => {
          form.removeClass('form--submitting');

          if ( response.success ) {
            form.find('.alert-wrapper').append($('<div class="alert success">').html(response.data.message));
            form.trigger('reset');
          }
          else {
            form.find('.alert-wrapper').append($('<div class="alert error">').html(response.data.message));
          }
        })

        return false;        
      })

  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
