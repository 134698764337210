import getNumber from '@fromscratch-studio-gr/locale-to-number.js'

export default {

  init: () => {

    $('.cart-button:not(.initialized)')
      .each((index, el) => {
        const $el = $(el)
        const $form = $el.closest('form');
        const $input = $el.find('[data-cart-qty-input]');
        const $addButton = $el.find('[data-cart-add]');
        const $qtyButton = $el.find('[data-cart-qty');
        const $moreButton = $el.find('[data-cart-qty-add]');
        const $lessButton = $el.find('[data-cart-qty-remove]');
        const min = $input.data('min') || 1;
        const price = getNumber($input.data('price').toString(), document.documentElement.lang) ?? Number($input.data('price'));

        $input.on('change', e => {          
          const val = e.currentTarget.value;
          if ( val > 0 ) {
            $addButton.hide();
            $qtyButton.show();
          }
          else {
            $addButton.show();
            $qtyButton.hide();
          }
        })
        .trigger('change');

        $addButton.on('click', e => {
          e.preventDefault();
          $form.data('quantity', Number($form.data('quantity') || 0) + min).trigger('change');
          $form.data('subtotal', Number($form.data('subtotal') || 0) + ( min * price )).trigger('change');
          $input.val(min).trigger('change');
        })

        $moreButton.on('click', e => {
          e.preventDefault();
          const qty = Number($input.val())+1;
          $form.data('quantity', Number($form.data('quantity') || 0) + 1).trigger('change');
          $form.data('subtotal', Number($form.data('subtotal') || 0) + price).trigger('change');
          $input.val(qty).trigger('change');
        })

        $lessButton.on('click', e => {
          e.preventDefault();
          const qty = Number($input.val())-1;
          if ( qty >= min ) {
            $input.val(qty).trigger('change');
            $form.data('quantity', Number($form.data('quantity') || 0) - 1).trigger('change');
            $form.data('subtotal', Number($form.data('subtotal') || 0) - price).trigger('change');
          }
          else {
            $input.val(0).trigger('change');
            $form.data('quantity', Number($form.data('quantity') || 0) - min).trigger('change');
            $form.data('subtotal', Number($form.data('subtotal') || 0) - ( min * price )).trigger('change');
          }
        });

        // update form based on quantity items in cart

        $form
          .off('change')
          .on('change', e => {
            const $form = $(e.currentTarget);
            const quantity = $form.data('quantity') || 0;
            const subtotal = $form.data('subtotal') || 0;
            $('[data-cart-quantity]').text(quantity).toggleClass('visible', quantity > 0);
            $('[data-cart-subtotal]').text(subtotal.toLocaleString(document.documentElement.lang, { style: 'currency', currency: 'CAD' })).toggleClass('visible', subtotal > 0);
            $form.toggleClass('disabled', quantity <= 0);
          });

        $el.show().addClass('initialized');

        $el.on('reset', () => {
          $input.val(0).trigger('change');
          $form.data('quantity', 0).trigger('change');
          $form.data('subtotal', 0).trigger('change');
        })
      })

  }

}
