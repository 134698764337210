import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export default {
  init() {
    tippy('.product-card .nutrition', {
      theme: 'nutrition',
      content(reference) {
        const id = reference.getAttribute('data-template');
        const template = document.getElementById(id);
        return template.innerHTML;
      },
      allowHTML: true,
    })
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
